import React, { useState, useRef, useEffect } from 'react';
import './ImageToAscii.css';
import defaultImage from './assets/hisoka.jpg';

const ImageToAscii = () => {
  const [asciiArt, setAsciiArt] = useState('[This is where your ~art~ will be]');
  const [maxDimension, setMaxDimension] = useState(40);
  const [uploadedImageSrc, setUploadedImageSrc] = useState(null);
  const [contrast, setContrast] = useState(100);
  const [brightness, setbrightness] = useState(0);

  const [imageInstance, setImageInstance] = useState(null);
  const [originalImageSrc, setOriginalImageSrc] = useState(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(asciiArt).then(
      () => {
        alert("ASCII art copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };
  
  const loadDefaultImage = () => {
    const img = new Image();
    img.src = defaultImage;
    img.onload = () => {
      setImageInstance(img);
      setUploadedImageSrc(defaultImage);
      setOriginalImageSrc(defaultImage);
      applyFilters(img);
    };
  };

  useEffect(() => {
    loadDefaultImage()
    // console.log("using effect");
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const uploadedImageRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.onload = (progressEvent) => {
      const img = new Image();
      img.src = progressEvent.target.result;
      setImageInstance(img);
      setUploadedImageSrc(progressEvent.target.result);

      img.onload = async () => {
        setOriginalImageSrc(img.src);
        applyFilters(img);
      };
    };
    fileReader.readAsDataURL(file);
  };

  const applyFilters = (img) => {
    const canvas = document.getElementById('preview-canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;

    const sharpness = mapRange(brightness, -100, 100, 50, 150);
    ctx.filter = `contrast(${contrast}%) brightness(${sharpness}%)`;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    const filteredImageSrc = canvas.toDataURL();
    setUploadedImageSrc(filteredImageSrc);
  };
  

  const mapRange = (value, inputMin, inputMax, outputMin, outputMax) => {
    return ((value - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) + outputMin;
  };

  const debouncedApplyFilters = debounce(applyFilters, 250);

  const generateArt = async () => {
    if (uploadedImageSrc) {
      const img = new Image();
      img.src = uploadedImageSrc;

      img.onload = async () => {
        const ascii = await convertImageToAscii(img);
        setAsciiArt(ascii);
      };
    }
  };

  const handleDimensionChange = (event) => {
    setMaxDimension(event.target.value);
  };

  const convertImageToAscii = async (img) => {
    const asciiChars = ['@', '#', '%', '?', '*', '+', ';', ':', ',', '.'];

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const aspectRatio = img.width / img.height;
    const characterAspectRatio = 2.0;

    if (img.width > img.height) {
      canvas.width = maxDimension;
      canvas.height = Math.round(maxDimension / (aspectRatio * characterAspectRatio));
    } else {
      canvas.height = maxDimension;
      canvas.width = Math.round(maxDimension * aspectRatio * characterAspectRatio);
    }

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const grayData = grayscale(imgData);

    const segments = divideIntoSegments(grayData);

    let ascii = '';
    for (let i = 0; i < segments.length; i++) {
      const asciiIndex = Math.floor(segments[i] * (asciiChars.length - 1) / 255);
      ascii += asciiChars[asciiIndex];

      if ((i + 1) % canvas.width === 0) {
        ascii += '\n';
      }
    }

    return ascii;
  };

  const grayscale = (imgData) => {
    for (let i = 0; i < imgData.data.length; i += 4) {
      const avg = (imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2]) / 3;
      imgData.data[i] = imgData.data[i + 1] = imgData.data[i + 2] = avg;
    }
    return imgData;
  };

  const divideIntoSegments = (imgData) => {
    const segments = [];
    for (let y = 0; y < imgData.height; y++) {
      for (let x = 0; x < imgData.width; x++) {
        const i = (y * imgData.width + x) * 4;
        segments.push(imgData.data[i]);
      }
    }
    return segments;
  };

  return (
    <div className="image-to-ascii">
      <label htmlFor='dimension-input'>Max Size</label>
      <input
        type="number"
        value={maxDimension}
        onChange={handleDimensionChange}
        min="10"
        max="200"
        className="dimension-input"
      />
      <div className="file-input-container">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          id="file-input"
          hidden
        />

        <label htmlFor="file-input" className="upload-button">
          Upload an Image
        </label>
        {uploadedImageSrc && (
          <img
            src={uploadedImageSrc}
            alt="Uploaded preview"
            className="uploaded-image"
            ref={uploadedImageRef}
          />
        )}
      </div>
      <div className="filter-controls">
        <label htmlFor="contrast-input">Contrast:</label>
        <input
          type="range"
          min="0"
          max="200"
          value={contrast}
          onChange={(e) => {
            setContrast(e.target.value);
            if (imageInstance) {
              imageInstance.onload = null;
              imageInstance.src = originalImageSrc;
              imageInstance.onload = () => {
                debouncedApplyFilters(imageInstance);
              };
            }
          }}

          id="contrast-input"
        />
        <label htmlFor="brightness-input">Brightness:</label>
        <input
          type="range"
          min="-100"
          max="100"
          value={brightness}
          onChange={(e) => {
            setbrightness(e.target.value);
            if (imageInstance) {
              imageInstance.onload = null;
              imageInstance.src = originalImageSrc;
              imageInstance.onload = () => {
                debouncedApplyFilters(imageInstance);
              };
            }
          }}

          id="brightness-input"
        />
        <canvas id="preview-canvas" className="preview-canvas" hidden></canvas>
      </div>
      <button onClick={generateArt} className="generate-art-button">
        Generate Art
      </button>
      <pre className="ascii-output">{asciiArt}</pre>
      <button onClick={copyToClipboard} className="copy-to-clipboard-button">
        Copy to Clipboard
      </button>
    </div>
  );
};

export default ImageToAscii;