import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import "./App.css";

const BlogDisplay = () => {
  const [blog, setBlog] = useState(null);
  const [content, setContent] = useState("");
  const { blogPath } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      const blogsRef = collection(db, "blogs");
      const blogsQuery = query(blogsRef, where("path", "==", blogPath));
      const snapshot = await getDocs(blogsQuery);

      if (snapshot.empty) {
        console.log("No matching blog found");
        return;
      }

      const blogData = snapshot.docs[0].data();
      const contentDocRef = doc(db, "blogContents", blogData.contentRef);
      const contentDoc = await getDoc(contentDocRef);
      const contentData = contentDoc.data();

      setBlog(blogData);
      setContent(contentData.content);
    };

    fetchBlog();
  }, [blogPath]);

  if (!blog) {
    return <div className="blog-wrapper">
      {<div className="loading-spinner"/>}
    </div>;
  }

  return (
    <div className='blog-wrapper'>
      {/* {<div className="loading-spinner"/>} */}
      <h1>{blog.title}</h1>
      <div>{content}</div>
    </div>
  );
};

export default BlogDisplay;
