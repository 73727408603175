import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './App.css';

const NavBar = (props) => {
    
  const path = useLocation().pathname;

  return (
    <nav className="navigation">

        {/* make it blend on the home page */}
        {path !== '/' && <div className="colorLayer"  />}
          
          <div className="navWrap">
            <ul className="nav-left">
              <li>
                <Link to="/">Aaron Pierron</Link>
              </li>
            </ul>
            <ul className="nav-right">
              <li>
                <Link to="/projects">Projects</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/art">Art</Link>
              </li>
            </ul>
          </div>
        </nav>
  );
};

export default NavBar;
