import React, { useEffect } from 'react';
import './ProjectsPage.css';
// import './App.css';
import ImageToAscii from './ImageToAscii.js';

const AsciiPage = () => {
  useEffect(() => {
    document.title = 'Aaron Pierron - ASCII Art Converter';
  }, []);

  return (
    <div className="ascii-wrapper">
      <h2>ASCII Art Converter</h2>
      <div className="image-to-ascii-container">
        <ImageToAscii />
      </div>
    </div>
  );
};

export default AsciiPage;
