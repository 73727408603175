import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import ProjectsPage from "./ProjectsPage";
import BlogPage from "./BlogPage";
import BlogDisplay from "./BlogDisplay";
import BlogEditor from "./BlogEditor";
import ArtPage from "./ArtPage";
import NavBar from "./NavBar";
import AsciiPage from './AsciiPage';
import ScrollToTop from "./ScrollToTop";

import "./App.css";

const App = () => {

  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Router>
      <div className="App">
        <ScrollToTop/>
        <NavBar />
        <button className="toggle-dark-mode" onClick={toggleDarkMode}>
          {darkMode ? "Light Mode" : "Dark Mode"}
        </button>
        <Routes>
          <Route path='/' element={<HomePage/>} />
          <Route path='/projects' element={<ProjectsPage/>} />
          <Route path='/blog' element={<BlogPage/>} />
          <Route path="/blog/:blogPath" element={<BlogDisplay/>} />
          <Route path='/editor' element={<BlogEditor/>}/>
          <Route path='/art' element={<ArtPage/>} />
          <Route path='/projects/ascii-art' element={<AsciiPage/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;