import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "./App.css";
import "./ProjectsPage.css";

const ProjectsPage = () => {

    useEffect(() => {
        document.title = "Aaron Pierron - Projects";
      }, []);

  return (
    <div className='projectsWrapper'>
      {/* <h2>Projects</h2> */}
      <div className='project-box-wrapper'>
      <Link to="/projects/ascii-art">
        <div className='project-box'>
          <h2>Image to ASCII Art</h2>
          <p>Every wanted to impress your friends with your ability to transcribe beautiful images into grungy ASCII art? Now you can! 
          </p>
        </div>
      </Link>
      </div>
    </div>
  );
};

export default ProjectsPage;
