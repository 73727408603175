import React, { useState, useEffect } from 'react';
// import "./App.css";
import { db } from './firebase';
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./BlogPage.css";

const BlogPage = () => {
  useEffect(() => {
    document.title = "Aaron Pierron - Blog";
  }, []);

  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(5);
  // const [blogsPerPage, setBlogsPerPage] = useState(5);
  // const [selectedBlogId, setSelectedBlogId] = useState(null);

  const navigate = useNavigate();

  const handleBlogClick = (blogPath) => {
    navigate(`/blog/${blogPath}`);
  };

  const fetchBlogs = async () => {
    const blogsRef = collection(db, "blogs");
    const blogsQuery = query(blogsRef, orderBy("createdAt", sortOrder));
    const snapshot = await getDocs(blogsQuery);
    
  
    const fetchedBlogs = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  
    setBlogs(fetchedBlogs);
  };

  useEffect(() => {
    fetchBlogs();
  }, [sortOrder]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBlogs = blogs.filter(blog =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginate = (blogs) => {
    const startIndex = (currentPage - 1) * blogsPerPage;
    const endIndex = startIndex + blogsPerPage;
    return blogs.slice(startIndex, endIndex);
  };

  const displayedBlogs = paginate(filteredBlogs);

  return (
    <div className="blog-container">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <select
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          className="sort-select"
        >
          <option value="desc">Newest</option>
          <option value="asc">Oldest</option>
        </select>
      </div>
      <div>
        {displayedBlogs.map((blog) => (
          <div key={blog.id} className='blog-post-box'>
          <div
            key={blog.id}
            className="blog-post"
            onClick={() => handleBlogClick(blog.path)}
          >
            <h3>{blog.title}</h3>
            <p>{blog.snippet}</p>
          </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className="page-button"
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}  

export default BlogPage;
