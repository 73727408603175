import React, { useState } from "react";
import "./BlogEditor.css";
import axios from "axios";


const BlogEditor = () => {
  const [title, setTitle] = useState("");
  const [path, setPath] = useState("");
  const [snippet, setSnippet] = useState("");
  const [content, setContent] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post("http://localhost:3001/blogs", {
        title: title,
        path: path,
        snippet: snippet,
        content: content,
      }, {
        headers: {
          "X-API-Key": process.env.REACT_APP_API_KEY,
        },
      }); // Should prob add a notif that it completed

      // Clear the form
      setTitle("");
      setPath("");
      setSnippet("");
      setContent("");
    } catch (error) {
      console.error("Error creating blog:", error);
    }
  };

  return (
    <div className="editor-container">
      <h2>Create a new blog post</h2>
      <form onSubmit={handleSubmit} className="editor-form">
        <label>
          Title: <br />
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </label>
        <br />
        <label>
          Path (URL hyphenated slug): <br />
          <input type="text" value={path} onChange={(e) => setPath(e.target.value)} required />
        </label>
        <br />
        <label>
          Snippet: <br />
          <textarea value={snippet} onChange={(e) => setSnippet(e.target.value)} required />
        </label>
        <br />
        <label>
          Content: <br />
          <textarea value={content} onChange={(e) => setContent(e.target.value)} required />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default BlogEditor;