import React, { useEffect } from 'react';
import "./App.css";
import ImageGallery from './ImageGallery';
import { storage } from './firebase';
import { ref } from 'firebase/storage';

const artRef = ref(storage, 'art');

const ArtPage = () => {
    useEffect(() => {
        document.title = "Aaron Pierron - Art";
      }, []);

  return (
    <div className='artWrapper'>
      <section className="text-box">
          <p>
          I'm really not much of an artist, but making art makes me happy.
          <br/>
          <br/>
          (The current images are filler by <a href="https://libreshot.com/" target="_blank" rel="noopener noreferrer" className="link">Martin Vorel.</a> I'll be updating this as soon as I finish some projects and get good scans of them. I hope you can find it in your heart to forgive me.)
          </p>
        </section>
      <ImageGallery imagesRef={artRef}/>
    </div>
  );
};

export default ArtPage;
