import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCQDJEYO4ibdfVjX9PBNRm655Z3K0cE3HU",
  authDomain: "aaronpierron-com.firebaseapp.com",
  projectId: "aaronpierron-com",
  storageBucket: "aaronpierron-com.appspot.com",
  messagingSenderId: "348990875385",
  appId: "1:348990875385:web:64f4bfd16b1b28cf6de428",
  measurementId: "G-D414HGEFKY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
// const analytics = getAnalytics(app);

// Use these exports instead of via "firebase/package" to make sure app is initialized
export { storage, db };