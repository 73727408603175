import React, { Component } from 'react';
import { getDownloadURL, list } from 'firebase/storage';
import './ImageGallery.css';

const PAGE_SIZE = 5;

class ImageGallery extends Component {
  state = {
    images: [],
    nextPageToken: null,
    allImagesFetched: false,
    focusedImage: null,
  };

  // DON'T PUT THIS IN STATE. Triggers some fuckery
  // with render pipeline, calling fetchImages twice.
  loading = false;

  componentDidMount() {
    this.fetchImages();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleImageClick = (image, description) => {
    document.documentElement.style.overflow = 'hidden';
    this.setState({ focusedImage: { image, description } });
  };

  closeFocusedImage = () => {
    document.documentElement.style.overflow = 'auto';
    this.setState({ focusedImage: null });
  };

  handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const threshold = scrollHeight - clientHeight - 200;
    if (scrollTop >= threshold && !this.state.loading && !this.state.allImagesFetched) {
      // Check if all images have been fetched
      this.fetchImages();
    }
  };

  fetchImages = async () => {
    // Return early if the component is already loading or all images have been fetched
    if (this.loading || this.state.allImagesFetched) {
      return;
    }

    this.loading = true;

    const { imagesRef } = this.props;
    const options = {
      maxResults: PAGE_SIZE,
    };

    if (this.state.nextPageToken) {
      options.pageToken = this.state.nextPageToken;
    }

    try {
      const result = await list(imagesRef, options);
      const imageUrls = await Promise.all(result.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        const title = itemRef.name;
        return { url, title };
      }));

      this.setState({
        images: this.state.images.concat(imageUrls),
        nextPageToken: result.nextPageToken || null,
        allImagesFetched: !result.nextPageToken,
      });

      this.loading = false;
      
    } catch (error) {
      console.error('Error fetching paginated images:', error);
      this.loading = false;
    }
  };

  render() {
    const { images, focusedImage } = this.state;

    return (
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={index} className="image-wrapper">
            <img
              src={image.url}
              alt={`${image.title}`}
              onClick={() => this.handleImageClick(image.url, `${image.title}`)}
            />
          </div>
        ))}
        {focusedImage && (
          <div className="focused-image-overlay" onClick={this.closeFocusedImage}>
            <div className="focused-image-wrapper">
              <div className="focused-image-content">
                <img src={focusedImage.image} alt="Focused" />
                <p className="image-description">{focusedImage.description}</p>
              </div>
            </div>
          </div>
        )}
        {this.loading && <div className="loading-spinner"/>}
      </div>
    );
  }
}

export default ImageGallery;
