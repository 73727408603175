import React, { useEffect } from 'react';
import "./App.css";

// don't change anything it's escaping chars
// const pacman = `.--.                     .-.
// / _.-\` .-.   .-.   .-.   |OO |
// \\  '-. '-'   '-'   '-'   |   |
//  \`--'                    '^^^'
// `

// Revised to fit on mobile
const pacman = `.--.               .-.
/ _.-\` .-.   .-.   |OO |
\\  '-. '-'   '-'   |   |
 \`--'              '^^^'
`
const messages = [pacman, "Welcome back!", "Glad you could make it.", "Stay a while, won't you?", "Wasn't expecting to see you here!", "What a pleasant surprise.", "I'd offer you a chair, but...",];

const HomePage = () => {
  useEffect(() => {
    document.title = "Aaron Pierron";
  }, []);

  var randomMessage = messages[Math.floor(Math.random() * messages.length)];
  if (randomMessage === pacman) {
    randomMessage = <React.Fragment><pre>{pacman}</pre></React.Fragment>
  }

  return (
    <div>
      <div className="colorLayer" />
      <div className="home-wrapper">
        <header className="home-header">
          <h1>{randomMessage}</h1>
        </header>
        <section className="text-box">
          <h2>Hi, I'm Aaron.</h2>
          <p>
            I make things. You'll find some of those things on this very website (how serendipitous)! Of course, this site is under active development, so give me a few weeks.
            <br />
            <br />
            Lately my focus has been drawn to AI research. I'm currently drafting a paper on minimizing misinformation, but we'll see if it actually bears fruit. In the meantime, poke around and see what catches your eye.
          </p>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
